import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PagedContents, ResultType, SavedType, WatchedType } from '../../models/user';
import { ReducerUser, Reducers } from '../../models/reducers';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../utilities/hooks';
import {
    getSavedContents,
    getWatchedContents,
    removeFromSaved,
    removeFromWatched,
    changeStatusWatched,
    addToWatched,
} from '../../api/user-contents-requests';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../utilities/enum/contentType';

import './ContentsGridComponent.scss';
import Content from '../../components/Content/Content';

interface Props {
    type: ContentType;
    list: 'saved' | 'watched';
    loadMore: boolean;
    onSuccess: (message: string) => void;
    onError: (message: string) => void;
    onLoading: (status: boolean) => void;
    onLoadMore: (status: boolean) => void;
}

const ContentsGridComponent: FC<Props> = ({ type, list, loadMore, onSuccess, onError, onLoading, onLoadMore }) => {
    const pageSize = 25;
    const { t } = useTranslation();
    const [movies, setMovies] = useState<SavedType[] | WatchedType[]>([]);
    const [currentPageMovies, setCurrentPageMovies] = useState<number>(1);
    const [totalMovies, setTotalMovies] = useState<number | null>(null);
    const [shows, setShows] = useState<SavedType[] | WatchedType[]>([]);
    const [currentPageShows, setCurrentPageShows] = useState<number>(1);
    const [totalShows, setTotalShows] = useState<number | null>(null);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const gridContainerRef = useRef<HTMLDivElement | null>(null);
    const previousPageMovies = usePrevious(currentPageMovies);
    const previousPageShows = usePrevious(currentPageShows);
    const previousType = usePrevious(type);
    const previousList = usePrevious(list);

    const handleFetchMovies = useCallback(
        async (
            currentMovies: SavedType[] | WatchedType[],
            type: ContentType,
            list: 'watched' | 'saved',
            currentPage: number,
            total: number | null,
        ) => {
            if (user.user && user.user.id) {
                onLoadMore(true);
                try {
                    if (list === 'saved') {
                        await getSavedContents(type, user.user.id, currentPage, pageSize)
                            .then((data) => {
                                if ((data as PagedContents<SavedType[]>).results) {
                                    const resultData = data as PagedContents<SavedType[]>;
                                    const filteredResult = resultData.results.filter(
                                        (result) => currentMovies.find((movie) => movie.id === result.id) === undefined,
                                    );
                                    const newArray = [...currentMovies, ...filteredResult];
                                    if (newArray.length < resultData.total) {
                                        setCanLoadMore(true);
                                    } else {
                                        setCanLoadMore(false);
                                    }
                                    if (total == null) {
                                        setTotalMovies(resultData.total);
                                    }
                                    setMovies(newArray);
                                }
                            })
                            .catch((error) => {
                                onError(t(`errors.${error.error}`) ?? t('errors.fetching_saved_error'));
                            });
                    }
                    if (list === 'watched') {
                        await getWatchedContents(type, user.user.id, currentPage, pageSize)
                            .then((data) => {
                                if ((data as PagedContents<WatchedType[]>).results) {
                                    const resultData = data as PagedContents<WatchedType[]>;
                                    const filteredResult = resultData.results.filter(
                                        (result) => currentMovies.find((movie) => movie.id === result.id) === undefined,
                                    );
                                    const newArray = [...currentMovies, ...filteredResult];
                                    if (newArray.length < resultData.total) {
                                        setCanLoadMore(true);
                                    } else {
                                        setCanLoadMore(false);
                                    }
                                    if (total == null) {
                                        setTotalMovies(resultData.total);
                                    }
                                    setMovies(newArray);
                                }
                            })
                            .catch((error) => {
                                onError(t(`errors.${error.error}`) ?? t('errors.fetching_saved_error'));
                            });
                    }
                } catch (error: any) {
                    if (error.error) {
                        onError(t(`errors.${error.error}`) ?? t('errors.fetching_list_error'));
                    } else {
                        onError(t('errors.fetching_list_error'));
                    }
                }
                onLoadMore(false);
            }
        },
        [onError, onLoadMore, t, user.user],
    );

    const handleFetchShows = useCallback(
        async (
            currentShows: SavedType[] | WatchedType[],
            type: ContentType,
            list: 'watched' | 'saved',
            currentPage: number,
            total: number | null,
        ) => {
            if (user.user && user.user.id) {
                onLoadMore(true);
                try {
                    if (list === 'saved') {
                        await getSavedContents(type, user.user.id, currentPage, pageSize)
                            .then((data) => {
                                if ((data as PagedContents<SavedType[]>).results) {
                                    const resultData = data as PagedContents<SavedType[]>;
                                    const filteredResult = resultData.results.filter(
                                        (result) => currentShows.find((show) => show.id === result.id) === undefined,
                                    );
                                    const newArray = [...currentShows, ...filteredResult];
                                    if (newArray.length < resultData.total) {
                                        setCanLoadMore(true);
                                    } else {
                                        setCanLoadMore(false);
                                    }
                                    if (total == null) {
                                        setTotalShows(resultData.total);
                                    }
                                    setShows(newArray);
                                }
                            })
                            .catch((error) => {
                                onError(t(`errors.${error.error}`) ?? t('errors.fetching_saved_error'));
                            });
                    }
                    if (list === 'watched') {
                        await getWatchedContents(type, user.user.id, currentPage, pageSize)
                            .then((data) => {
                                if ((data as PagedContents<WatchedType[]>).results) {
                                    const resultData = data as PagedContents<WatchedType[]>;
                                    const filteredResult = resultData.results.filter(
                                        (result) => currentShows.find((show) => show.id === result.id) === undefined,
                                    );
                                    const newArray = [...currentShows, ...filteredResult];
                                    if (newArray.length < resultData.total) {
                                        setCanLoadMore(true);
                                    } else {
                                        setCanLoadMore(false);
                                    }
                                    if (total == null) {
                                        setTotalShows(resultData.total);
                                    }
                                    setShows(newArray);
                                }
                            })
                            .catch((error) => {
                                onError(t(`errors.${error.error}`) ?? t('errors.fetching_watched_error'));
                            });
                    }
                } catch (error: any) {
                    if (error.error) {
                        onError(t(`errors.${error.error}`) ?? t('errors.fetching_list_error'));
                    } else {
                        onError(t('errors.fetching_list_error'));
                    }
                }
                onLoadMore(false);
            }
        },
        [onError, onLoadMore, t, user.user],
    );

    const handleRemoveContent = useCallback(
        (id: number) => {
            if (user.user?.id) {
                onLoading(true);
                if (list === 'saved') {
                    removeFromSaved(id)
                        .then(async (data) => {
                            if ((data as ResultType).status === 'ok') {
                                if (type === ContentType.MOVIE) {
                                    onSuccess(t('account_page.desktop.watchlist.movie_removed'));
                                    setMovies([...movies.filter((movie) => movie.id !== id)]);
                                    if (totalMovies != null) {
                                        setTotalMovies(totalMovies - 1);
                                    }
                                } else {
                                    onSuccess(t('account_page.desktop.watchlist.tv_show_removed'));
                                    setMovies([...shows.filter((show) => show.id !== id)]);
                                    if (totalShows != null) {
                                        setTotalMovies(totalShows - 1);
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            if (error.error) {
                                onError(t(`errors.${error.error}`) ?? t('errors.removing_saved_error'));
                            } else {
                                onError(t('errors.removing_saved_error'));
                            }
                        })
                        .finally(() => onLoading(false));
                }
                if (list === 'watched') {
                    removeFromWatched(id)
                        .then(async (data) => {
                            if ((data as ResultType).status === 'ok') {
                                if (type === ContentType.MOVIE) {
                                    onSuccess(t('account_page.desktop.watched.movie_removed'));
                                    setMovies([...movies.filter((movie) => movie.id !== id)]);
                                    if (totalMovies != null) {
                                        setTotalMovies(totalMovies - 1);
                                    }
                                } else {
                                    onSuccess(t('account_page.desktop.watched.tv_show_removed'));
                                    setMovies([...shows.filter((show) => show.id !== id)]);
                                    if (totalShows != null) {
                                        setTotalMovies(totalShows - 1);
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            if (error.error) {
                                onError(t(`errors.${error.error}`) ?? t('errors.removing_watched_error'));
                            } else {
                                onError(t('errors.removing_watched_error'));
                            }
                        })
                        .finally(() => onLoading(false));
                }
            }
        },
        [user.user?.id, onLoading, list, type, onSuccess, t, movies, totalMovies, shows, totalShows, onError],
    );

    const handleChangeStatus = useCallback(
        async (content: WatchedType) => {
            if (user.user?.id && content.id) {
                changeStatusWatched(!content.isLike, user.user.id, content.id)
                    .then(async (data) => {
                        if ((data as WatchedType).id) {
                            if (type === ContentType.MOVIE) {
                                onSuccess(t('account_page.desktop.watched.movie_updated'));
                                setMovies(
                                    (movies as WatchedType[]).map((movie) => {
                                        if (movie.id === content.id) {
                                            return {
                                                ...movie,
                                                isLike: !movie.isLike,
                                            };
                                        }
                                        return movie;
                                    }),
                                );
                            } else {
                                onSuccess(t('account_page.desktop.watched.tv_show_updated'));
                                setShows(
                                    (shows as WatchedType[]).map((show) => {
                                        if (show.id === content.id) {
                                            return {
                                                ...show,
                                                isLike: !show.isLike,
                                            };
                                        }
                                        return show;
                                    }),
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.error) {
                            onError(t(`errors.${error.error}`) || t(`errors.updating_watched_error`));
                        } else {
                            onError(t(`errors.updating_watched_error`));
                        }
                    })
                    .finally(() => onLoading(false));
            }
        },
        [user.user?.id, type, onSuccess, t, movies, shows, onError, onLoading],
    );

    const handleAddToWatchedContent = useCallback(
        async (content: SavedType, status: boolean) => {
            if (user.user && user.user.id && content.id) {
                onLoading(true);
                try {
                    const item: WatchedType = {
                        idUser: user.user?.id,
                        type: type,
                        idContent: content.idContent,
                        title: content.title,
                        img: content.img,
                        isLike: status,
                    };
                    await addToWatched(item)
                        .then((data) => {
                            if ((data as WatchedType).id) {
                                if (type === ContentType.MOVIE) {
                                    onSuccess(t('account_page.desktop.watchlist.movie_added_watched'));
                                    setMovies([...movies.filter((movie) => movie.id !== content.id)]);
                                }
                                if (type === ContentType.TV_SHOW) {
                                    onSuccess(t('account_page.desktop.watchlist.tv_show_added_watched'));
                                    setShows([...shows.filter((show) => show.id !== content.id)]);
                                }
                            } else {
                                onError(t('errors.adding_watched_error'));
                            }
                        })
                        .catch((error) => {
                            throw error;
                        });
                    await removeFromSaved(content.id)
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {
                            throw error;
                        });
                } catch (error: any) {
                    if (error.error) {
                        onError(t(`errors.${error.error}`) || t(`errors.adding_watched_error`));
                    } else {
                        onError(t(`errors.adding_watched_error`));
                    }
                }
                onLoading(false);
            }
        },
        [user.user, onLoading, type, onSuccess, t, movies, shows, onError],
    );

    const renderGrid = useMemo(() => {
        if (type === ContentType.MOVIE) {
            return movies.map((movie) => {
                return (
                    <Content
                        key={movie.id}
                        type={list}
                        content={movie}
                        contentType={type}
                        onRemove={() => {
                            handleRemoveContent(movie.id ?? -1);
                        }}
                        onChangeStatus={() => {
                            handleChangeStatus(movie as WatchedType);
                        }}
                        onWatched={(status) => handleAddToWatchedContent(movie, status)}
                    />
                );
            });
        }
        if (type === ContentType.TV_SHOW) {
            return shows.map((show) => {
                return (
                    <Content
                        key={show.id}
                        type={list}
                        content={show}
                        contentType={type}
                        onRemove={() => {
                            handleRemoveContent(show.id ?? -1);
                        }}
                        onChangeStatus={() => {
                            handleChangeStatus(show as WatchedType);
                        }}
                        onWatched={(status) => handleAddToWatchedContent(show, status)}
                    />
                );
            });
        }
    }, [type, movies, list, handleRemoveContent, handleChangeStatus, handleAddToWatchedContent, shows]);

    useEffect(() => {
        if (list !== previousList) {
            setMovies([]);
            setShows([]);
            setTotalMovies(null);
            setTotalShows(null);
            setCurrentPageMovies(1);
            setCurrentPageShows(1);
        }
    }, [list, previousList]);

    useEffect(() => {
        if (list !== previousList) {
            setMovies([]);
            setShows([]);
            setTotalMovies(null);
            setTotalShows(null);
            setCurrentPageMovies(1);
            setCurrentPageShows(1);
            if (type === ContentType.MOVIE) {
                handleFetchMovies([], ContentType.MOVIE, list, 1, null);
            } else {
                handleFetchShows([], ContentType.TV_SHOW, list, 1, null);
            }
        } else {
            if (type === ContentType.MOVIE) {
                if (currentPageMovies !== previousPageMovies || previousType === undefined || previousType !== type) {
                    if ((totalMovies != null && movies.length < totalMovies) || totalMovies === null) {
                        handleFetchMovies(movies, ContentType.MOVIE, list, currentPageMovies, totalMovies);
                    }
                }
            } else {
                if (previousPageShows !== currentPageShows || previousType === undefined || previousType !== type) {
                    if ((totalShows != null && shows.length < totalShows) || totalShows === null) {
                        handleFetchShows(shows, ContentType.TV_SHOW, list, currentPageShows, totalShows);
                    }
                }
            }
        }
    }, [
        currentPageMovies,
        currentPageShows,
        type,
        movies,
        shows,
        totalMovies,
        totalShows,
        handleFetchMovies,
        handleFetchShows,
        previousPageMovies,
        previousPageShows,
        previousType,
        list,
        previousList,
    ]);

    useEffect(() => {
        const handleScroll = () => {
            const container = gridContainerRef.current;
            if (!container || loadMore) return;
            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                if (canLoadMore) {
                    if (type === ContentType.MOVIE) {
                        setCurrentPageMovies(currentPageMovies != null ? currentPageMovies + 1 : 1);
                    } else {
                        setCurrentPageShows(currentPageShows != null ? currentPageShows + 1 : 1);
                    }
                }
            }
        };

        const container = gridContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [canLoadMore, currentPageMovies, currentPageShows, loadMore, type]);

    if (movies.length === 0 && shows.length === 0) {
        return (
            <p className="noContents">
                {list === 'saved' ? t('account_page.desktop.no_saved') : t('account_page.desktop.no_watched')}
            </p>
        );
    }

    return (
        <div className="contentsGrid" ref={gridContainerRef}>
            {renderGrid}
        </div>
    );
};

export default ContentsGridComponent;
