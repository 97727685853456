import Header from '../../../components/Header/Header';
import Loader from '../../../components/Loader/Loader';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Toast from '../../../components/Toast';
import { Feeling, Movie, TvShow } from '../../../models/data';
import { User } from '../../../models/user';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GreyButton from '../../../components/GreyButton/GreyButton';
import ButtonIcon from '../../../components/ButtonIcon';
import useWindowDimensions from '../../../utilities/hooks';
import LikePopover from '../../../components/Popovers/LikePopover';
import { getTimeString } from '../../../utilities/functions';
import { COLORS, baseUrlImage } from '../../../resources/constants';
import Icon from '../../../components/Icon/Icon';
import { getYear } from 'date-fns';
import { ReducerData, Reducers } from '../../../models/reducers';
import { useSelector } from 'react-redux';
import FeelingItem from '../../../components/FeelingItem/FeelingItem';
import Button from '../../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../resources/routes-constants';
import Chip from '../../../components/Chip/Chip';
import { ContentType } from '../../../utilities/enum/contentType';

import './ResultPageMobile.scss';
import JustWatchReference from '../../../components/JustWatchReference/JustWatchReference';
interface Props {
    content: Movie | TvShow | null;
    type: ContentType;
    removeContent: boolean;
    isWatched: boolean;
    isInWatchList: boolean;
    isLike: boolean;
    user: User | null;
    loadingContent: boolean;
    success: string;
    error: string;
    loading: boolean;
    onRemoveContent: () => void;
    onCancelDelete: () => void;
    onConfirmDelete: () => void;
    onOtherContentClick: (type: ContentType) => void;
    onSaveContent: () => void;
    onAddToWatched: (isLike: boolean) => void;
    onChangeLike: () => void;
    onCloseToast: () => void;
}

const ResultPageMobile: FC<Props> = ({
    content,
    type,
    removeContent,
    isWatched,
    isInWatchList,
    isLike,
    user,
    success,
    error,
    loadingContent,
    loading,
    onRemoveContent,
    onCancelDelete,
    onConfirmDelete,
    onOtherContentClick,
    onSaveContent,
    onAddToWatched,
    onChangeLike,
    onCloseToast,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const [likeAction, setLikeAction] = useState<HTMLDivElement | null>(null);
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const navigate = useNavigate();
    const { contentId } = useParams();

    const onCancelLoginModal = useCallback(() => {
        setShowLoginModal(false);
    }, []);

    const onConfirmLoginModal = useCallback(() => {
        navigate(ROUTES.LOGIN_PAGE);
    }, [navigate]);

    const handleWatchlistClick = useCallback(() => {
        if (user) {
            onSaveContent();
        } else {
            setShowLoginModal(true);
        }
    }, [onSaveContent, user]);

    const handleWatchedClick = useCallback(
        (target: HTMLDivElement) => {
            if (user) {
                setLikeAction(target);
            } else {
                setShowLoginModal(true);
            }
        },
        [user],
    );

    const renderStars = useMemo(() => {
        if (content && content.voteAverage) {
            const val = Math.round(content.voteAverage / 2);
            const arrayStars = [];
            for (let i = 0; i < 5; i++) {
                if (i < val) {
                    arrayStars.push(<Icon key={i} name="star-fill" color={COLORS.yellow} size={width * 0.05} />);
                } else {
                    arrayStars.push(<Icon key={i} name="star-void" color={COLORS.yellow} size={width * 0.05} />);
                }
            }
            return <div className="resultContainer-contentMobile_coverContainer__meta--stars">{arrayStars}</div>;
        }
        return null;
    }, [content, width]);

    const renderWatchlistIcon = useMemo(() => {
        if (!isWatched) {
            if (isInWatchList) {
                return <ButtonIcon icon="remove" onClick={onRemoveContent} size={width * 0.1} />;
            }
            return <ButtonIcon icon="watchlist" onClick={handleWatchlistClick} size={width * 0.1} />;
        } else {
            return null;
        }
    }, [isWatched, isInWatchList, handleWatchlistClick, width, onRemoveContent]);

    const renderWatchedIcon = useMemo(() => {
        if (content) {
            if (isWatched) {
                return (
                    <div>
                        <ButtonIcon icon={isLike ? 'like' : 'dislike'} onClick={onChangeLike} size={width * 0.1} />
                    </div>
                );
            } else {
                return (
                    <>
                        <ButtonIcon icon={'favorite'} onClick={handleWatchedClick} size={width * 0.1} />
                        <LikePopover
                            element={likeAction}
                            onClose={() => setLikeAction(null)}
                            onAdd={(isLike) => {
                                onAddToWatched(isLike);
                                setLikeAction(null);
                            }}
                            size={width * 0.08}
                        />
                    </>
                );
            }
        }
        return null;
    }, [content, isWatched, isLike, onChangeLike, width, handleWatchedClick, likeAction, onAddToWatched]);

    const renderCover = useMemo(() => {
        if (content) {
            return (
                <div className="resultContainer-contentMobile_coverContainer">
                    <div className="resultContainer-contentMobile_coverContainer__meta">
                        {content.trailer ? (
                            <GreyButton
                                icon="trailer"
                                onClick={() => {
                                    window.open(content.trailer as string, '_blank');
                                }}
                                style={{
                                    flex: '0 0 13vw',
                                    width: '13vw',
                                    height: '13vw',
                                    maxWidth: 40,
                                    maxHeight: 40,
                                    marginBottom: 20,
                                }}
                            />
                        ) : (
                            <div />
                        )}
                        <div className="resultContainer-contentMobile_coverContainer__meta--duration -rotate">
                            <Icon name="duration" size={20} color="rgba(255,255,255,0.6)" />
                            {type === ContentType.MOVIE
                                ? getTimeString((content as Movie).runtime)
                                : `${(content as TvShow).episodes} ${t('result_page.common.episodes')}`}
                        </div>
                        <div className="resultContainer-contentMobile_coverContainer__meta--duration -rotate">
                            <Icon name="calendar" size={20} color="rgba(255,255,255,0.6)" />
                            {getYear(content.releaseDate)}
                        </div>
                        {renderStars}
                    </div>
                    <div className="resultContainer-contentMobile_coverContainer__cover">
                        <img src={content.poster as string} alt={content.name} />
                        <div className="resultContainer-contentMobile_coverContainer__cover--buttons">
                            {renderWatchedIcon}
                            {renderWatchlistIcon}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }, [content, renderStars, renderWatchedIcon, renderWatchlistIcon, t, type]);

    const renderTitle = useMemo(() => {
        if (content) {
            return <h2 className="resultContainer-contentMobile_data__title">{content.name}</h2>;
        }
        return null;
    }, [content]);

    const renderDirector = useMemo(() => {
        if (content && content.director) {
            return <p className="resultContainer-contentMobile_data__director">{content.director.name}</p>;
        }
        return null;
    }, [content]);

    const renderGenres = useMemo(() => {
        if (content) {
            return (
                <div className="resultContainer-contentMobile_data__genresCarousel">
                    {content.genres.map((genre) => {
                        const isSelected = user ? user.genres.find((id) => id === genre.id) : undefined;
                        return (
                            <Chip
                                key={`genre${genre.id}`}
                                name={genre.name}
                                selected={isSelected !== undefined}
                                type={type}
                            />
                        );
                    })}
                </div>
            );
        }
        return null;
    }, [content, type, user]);

    const renderPlot = useMemo(() => {
        if (content) {
            return (
                <div className="resultContainer-contentMobile_data__plot">
                    <h3>{t('result_page.mobile.plot')}</h3>
                    <p>{content.overview}</p>
                </div>
            );
        }
        return null;
    }, [content, t]);

    const renderCast = useMemo(() => {
        if (content) {
            return (
                <>
                    <h3>{t('result_page.mobile.cast')}</h3>
                    <div className="resultContainer-contentMobile_data__cast">
                        {content.cast.map((actor) => {
                            return (
                                <div className="resultContainer-contentMobile_data__cast--actor">
                                    <img
                                        src={actor.img ?? require('../../../resources/images/default-actor.png')}
                                        alt={actor.name}
                                    />
                                    <span>{actor.name}</span>
                                </div>
                            );
                        })}
                    </div>
                </>
            );
        }
        return null;
    }, [content, t]);

    const renderFeelings = useMemo(() => {
        if (content && data.feelings.length > 0) {
            const feelingsArray: Feeling[] = [];
            if (type === ContentType.MOVIE) {
                data.feelings.forEach((feeling) => {
                    const findGenre = feeling.movieGenres.find(
                        (genre) => content.genres.find((g) => g.id === genre) !== undefined,
                    );
                    if (findGenre) {
                        feelingsArray.push(feeling);
                    }
                });
            }
            if (type === ContentType.TV_SHOW) {
                data.feelings.forEach((feeling) => {
                    const findGenre = feeling.movieGenres.find(
                        (genre) => content.genres.find((g) => g.id === genre) !== undefined,
                    );
                    if (findGenre) {
                        feelingsArray.push(feeling);
                    }
                });
            }
            if (feelingsArray.length > 0) {
                return (
                    <>
                        <h3>{t('result_page.mobile.good_for')}</h3>
                        <div className="resultContainer-contentMobile_data__feelings">
                            {feelingsArray.map((feeling) => {
                                return (
                                    <FeelingItem
                                        feeling={feeling}
                                        onClick={() => {
                                            return;
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </>
                );
            }
        }
        return null;
    }, [content, data.feelings, t, type]);

    const renderPlatforms = useMemo(() => {
        if (content && content.providers) {
            if (content.providers.length > 0) {
                const uniqueProviders = content.providers.filter(
                    (provider, index, self) => index === self.findIndex((o) => o.id === provider.id),
                );
                return (
                    <>
                        <h3>{t('result_page.mobile.watch_on')}</h3>
                        <div className="resultContainer-contentMobile_data__providers">
                            {uniqueProviders.map((provider) => {
                                return (
                                    <GreyButton
                                        onClick={() => {
                                            return;
                                        }}
                                        image={`${baseUrlImage}${provider.logo}`}
                                        key={`resultPageMobileProvider${provider.id}`}
                                        text={provider.name}
                                    />
                                );
                            })}
                        </div>
                        <JustWatchReference />
                    </>
                );
            }
        }
        return null;
    }, [content, t]);

    const renderButtonMovie = useMemo(() => {
        if (content && !contentId) {
            return (
                <Button
                    onClick={() => onOtherContentClick(ContentType.MOVIE)}
                    styleClass="solidPurple"
                    isUppercase
                    text={
                        type === ContentType.MOVIE
                            ? t('result_page.mobile.search_another_movie')
                            : t('result_page.mobile.search_a_movie')
                    }
                />
            );
        }
        return null;
    }, [content, contentId, type, t, onOtherContentClick]);

    const renderButtonTvShow = useMemo(() => {
        if (content && !contentId) {
            return (
                <Button
                    onClick={() => onOtherContentClick(ContentType.TV_SHOW)}
                    isUppercase
                    styleClass="solidYellow"
                    text={
                        type === ContentType.TV_SHOW
                            ? t('result_page.mobile.search_another_tv_show')
                            : t('result_page.mobile.search_a_tv_show')
                    }
                />
            );
        }
        return null;
    }, [content, type, t, contentId, onOtherContentClick]);

    return (
        <div className="resultContainer">
            <Header
                isInDetail={loadingContent ? false : true}
                type={type}
                otherFilmClick={() => onOtherContentClick(ContentType.MOVIE)}
                otherShowClick={() => onOtherContentClick(ContentType.TV_SHOW)}
                hideOtherSearchButtons={contentId ? true : false}
            />
            {loadingContent ? (
                <div className="resultContainer-loading">
                    <Loader type={type ? type : ''} />
                </div>
            ) : (
                <div className="resultContainer-contentMobile">
                    {renderCover}
                    <div className="resultContainer-contentMobile_data">
                        <div>
                            {renderTitle}
                            {renderDirector}
                        </div>
                        {renderGenres}
                        {renderPlot}
                        {renderCast}
                        {renderPlatforms}
                        {renderFeelings}
                        {renderButtonMovie}
                        {renderButtonTvShow}
                        <p>{t('result_page.mobile.or')}</p>
                        <Button
                            text={t('result_page.mobile.change_settings')}
                            onClick={() => {
                                navigate(ROUTES.FILTER_PAGE);
                            }}
                            isUppercase
                        />
                        <Button
                            styleClass="revertGradientBG"
                            text={t('result_page.mobile.select_feeling')}
                            onClick={() => {
                                navigate(ROUTES.FEELINGS_PAGE);
                            }}
                            isUppercase
                        />
                    </div>
                </div>
            )}
            {loading && content ? <LoadingModal isVisible={loading} /> : null}
            <Toast message={error || success} onClose={onCloseToast} type={success ? 'success' : 'error'} />
            <ConfirmModal
                isVisible={removeContent}
                answer={t('result_page.desktop.do_you_want_to_remove_content')}
                cancelText={t('result_page.desktop.do_you_want_to_remove_content_no')}
                confirmText={t('result_page.desktop.do_you_want_to_remove_content_yes')}
                onCancel={onCancelDelete}
                onConfirm={onConfirmDelete}
            />
            <ConfirmModal
                isVisible={showLoginModal}
                answer={t('result_page.mobile.do_you_want_login')}
                cancelText={t('result_page.desktop.do_you_want_login_no')}
                confirmText={t('result_page.mobile.do_you_want_login_yes')}
                onCancel={onCancelLoginModal}
                onConfirm={onConfirmLoginModal}
            />
        </div>
    );
};

export default ResultPageMobile;
