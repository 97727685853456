import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendAnonymData } from '../../api/db-requests';
import { ROUTES } from '../../resources/routes-constants';
import { Filter } from '../../models/data';
import { ReducerUser, Reducers } from '../../models/reducers';
import { format } from 'date-fns';
import { editUser } from '../../api/users-requests';
import { setUser } from '../../store/actions/user';
import { User } from '../../models/user';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import FilterPageDesktop from './Desktop/FilterPageDesktop';
import { ContentType } from '../../utilities/enum/contentType';
import FilterPageMobile from './Mobile/FilterPageMobile';

export const initialFilter: Filter = {
    genres: [],
    startDate: '1950-01-01',
    endDate: format(new Date(), 'yyyy-MM-dd'),
    providers: [],
};

const FilterPage: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSearch = useCallback(
        (filter: Filter, type: ContentType) => {
            if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
                sendAnonymData(filter, type, -1);
            }
            navigate(ROUTES.RESULT_PAGE, {
                state: {
                    type: type,
                    filter: filter,
                },
            });
        },
        [navigate],
    );

    const handleSetPreference = useCallback(
        (filter: Filter) => {
            if (user.user) {
                setLoading(true);
                const platforms = filter.providers.map((provider) => {
                    return provider.id;
                });
                const genres = filter.genres.map((genre) => {
                    return genre.id;
                });
                editUser(user.user.id, { platforms, genres })
                    .then((response) => {
                        if ((response as User).id && user.user) {
                            dispatch(
                                setUser({
                                    ...user.user,
                                    platforms,
                                    genres,
                                }),
                            );
                            setSuccess('Preferenze salvate correttamente');
                        } else {
                            setError('Si è verificato un errore durante il salvataggio');
                        }
                    })
                    .catch((error) => setError(`Si è verificato un errore durante il salvataggio: ${error.toString()}`))
                    .finally(() => setLoading(false));
            }
        },
        [user.user, dispatch],
    );

    const handleGoToLogin = useCallback(() => {
        navigate(ROUTES.LOGIN_PAGE);
    }, [navigate]);

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    const handleGoBack = useCallback(() => {
        if (location.key !== 'default') {
            navigate(-1);
        } else {
            navigate(ROUTES.HOME_PAGE);
        }
    }, [location.key, navigate]);

    return (
        <ViewportSwitchComponent
            desktopComponent={
                <FilterPageDesktop
                    user={user.user}
                    success={success}
                    error={error}
                    loading={loading}
                    onGoBack={handleGoBack}
                    onSearch={handleSearch}
                    onSetPreferences={handleSetPreference}
                    onCloseToast={handleCloseToast}
                    onGoToLogin={handleGoToLogin}
                />
            }
            mobileComponent={
                <FilterPageMobile
                    user={user.user}
                    success={success}
                    error={error}
                    loading={loading}
                    onGoBack={handleGoBack}
                    onSearch={handleSearch}
                    onSetPreferences={handleSetPreference}
                    onCloseToast={handleCloseToast}
                    onGoToLogin={handleGoToLogin}
                />
            }
        />
    );
};

export default FilterPage;
