import { DATA_ACTIONS } from '../../store/actions/data';
import { ReducerData, ReduxActionData } from '../../models/reducers';

const defaultValue: ReducerData = {
    movie: null,
    tvshow: null,
    latestIdMovie: 0,
    latestIdShow: 0,
    movieGenres: [],
    showGenres: [],
    movieProvider: [],
    showProvider: [],
    dataFound: true,
    feelings: [],
    isInstallablePwa: false,
};

const dataState = (state: ReducerData = defaultValue, action: ReduxActionData<any>): ReducerData => {
    switch (action.type) {
        case DATA_ACTIONS.SET_MOVIE:
            return {
                ...state,
                movie: action.payload,
            };
        case DATA_ACTIONS.SET_TVSHOW:
            return {
                ...state,
                tvshow: action.payload,
            };
        case DATA_ACTIONS.SET_LATEST_ID_MOVIE:
            return {
                ...state,
                latestIdMovie: action.payload,
            };
        case DATA_ACTIONS.SET_LATEST_ID_SHOW:
            return {
                ...state,
                latestIdShow: action.payload,
            };
        case DATA_ACTIONS.SET_MOVIE_GENRES:
            return {
                ...state,
                movieGenres: action.payload,
            };
        case DATA_ACTIONS.SET_SHOW_GENRES:
            return {
                ...state,
                showGenres: action.payload,
            };
        case DATA_ACTIONS.SET_MOVIE_PROVIDERS:
            return {
                ...state,
                movieProvider: action.payload,
            };
        case DATA_ACTIONS.SET_SHOW_PROVIDERS:
            return {
                ...state,
                showProvider: action.payload,
            };
        case DATA_ACTIONS.SET_DATA_FOUND:
            return {
                ...state,
                dataFound: action.payload,
            };
        case DATA_ACTIONS.SET_FEELINGS:
            return {
                ...state,
                feelings: action.payload,
            };
        case DATA_ACTIONS.SET_IS_INSTALLABLE_PWA:
            return {
                ...state,
                isInstallablePwa: action.payload,
            };
        default:
            return state;
    }
};

export default dataState;
