import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import { SavedType, User, WatchedType } from '../../../../models/user';
import { getUserGenresArray, getUserProvidersArray } from '../../../../utilities/functions';
import { ReducerData, Reducers } from '../../../../models/reducers';
import { useSelector } from 'react-redux';
import { Genre, Provider } from '../../../../models/data';
import GreyButton from '../../../../components/GreyButton/GreyButton';
import Chip from '../../../../components/Chip/Chip';
import Toast from '../../../../components/Toast';
import { getLatestSavedContents, getLatestWatchedContents } from '../../../../api/user-contents-requests';
import { AccountView } from '../../../../utilities/enum/accountView';
import LoadingModal from '../../../../components/Modals/LoadingModal';

interface Props {
    user: User | null;
    onLogout: () => void;
    onChangeView: (view: AccountView) => void;
}

const AccountOverView: FC<Props> = ({ user, onLogout, onChangeView }) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [latestSaved, setLatestSaved] = useState<SavedType[]>([]);
    const [latestWatched, setLatestWatched] = useState<WatchedType[]>([]);

    const handleCloseToast = useCallback(() => {
        setError('');
    }, []);

    const fetchLatestSaved = useCallback(async () => {
        if (user) {
            setLoading(true);
            try {
                const contents = await getLatestSavedContents(user.id);
                setLatestSaved(contents.results);
            } catch (error) {
                setError(t('errors.fetching_saved_error'));
            }
            setLoading(false);
        }
    }, [user, t]);

    const fetchLatestWatched = useCallback(async () => {
        if (user) {
            setLoading(true);
            try {
                const contents = await getLatestWatchedContents(user.id);
                setLatestWatched(contents.results);
            } catch (error) {
                setError(t('errors.fetching_watched_error'));
            }
            setLoading(false);
        }
    }, [user, t]);

    const providers: Provider[] = useMemo(() => {
        if (user) {
            return getUserProvidersArray(user.platforms, data.movieProvider, data.showProvider);
        }
        return [];
    }, [data.movieProvider, data.showProvider, user]);

    const renderProviderCarousel = useMemo(() => {
        return (
            <div className="accountMobileContainer-content_mainRow">
                <div className="accountMobileContainer-content_actionRow">
                    <h3 className="accountMobileContainer-content_minorTitle">{t('account_page.mobile.platforms')}</h3>
                    <Button
                        text={t('account_page.mobile.edit')}
                        onClick={() => {
                            onChangeView(AccountView.PLATFORMS);
                        }}
                        styleClass="linkYellow"
                    />
                </div>
                <div className="accountMobileContainer-content_carousel -providers">
                    {providers.length > 0 ? (
                        providers.map((provider) => {
                            return (
                                <GreyButton
                                    onClick={() => {
                                        return;
                                    }}
                                    image={`${provider.logo}`}
                                    key={`accountOverViewProvider${provider.id}`}
                                    text={provider.name}
                                />
                            );
                        })
                    ) : (
                        <p className="accountMobileContainer-content_carousel__noElements">
                            {t('account_page.mobile.no_providers')}
                        </p>
                    )}
                </div>
            </div>
        );
    }, [onChangeView, providers, t]);

    const genres: Genre[] = useMemo(() => {
        if (user) {
            return getUserGenresArray(user.genres, data.movieGenres, data.showGenres);
        }
        return [];
    }, [data.movieGenres, data.showGenres, user]);

    const renderGenreCarousel = useMemo(() => {
        return (
            <div className="accountMobileContainer-content_mainRow">
                <div className="accountMobileContainer-content_actionRow">
                    <h3 className="accountMobileContainer-content_minorTitle">{t('account_page.mobile.genres')}</h3>
                    <Button
                        text={t('account_page.mobile.edit')}
                        onClick={() => {
                            onChangeView(AccountView.GENRES);
                        }}
                        styleClass="linkYellow"
                    />
                </div>
                <div className="accountMobileContainer-content_carousel -genres">
                    {genres.length > 0 ? (
                        genres.map((genre) => {
                            return <Chip name={genre.name} />;
                        })
                    ) : (
                        <p className="accountMobileContainer-content_carousel__noElements">
                            {t('account_page.mobile.no_genres')}
                        </p>
                    )}
                </div>
            </div>
        );
    }, [genres, onChangeView, t]);

    const renderLatestSavedCarousel = useMemo(() => {
        return (
            <div className="accountMobileContainer-content_mainRow">
                <div className="accountMobileContainer-content_actionRow">
                    <h3 className="accountMobileContainer-content_minorTitle">{t('account_page.mobile.saved')}</h3>
                    {latestSaved.length > 0 ? (
                        <Button
                            text={t('account_page.mobile.view_all')}
                            onClick={() => {
                                onChangeView(AccountView.SAVED);
                            }}
                            styleClass="linkYellow"
                        />
                    ) : null}
                </div>
                <div className="accountMobileContainer-content_carousel -contents">
                    {latestSaved.length > 0 ? (
                        latestSaved.map((saved) => {
                            return (
                                <div>
                                    <img src={saved.img} alt={saved.title} />
                                </div>
                            );
                        })
                    ) : (
                        <p className="accountMobileContainer-content_carousel__noElements">
                            {t('account_page.mobile.no_saved')}
                        </p>
                    )}
                </div>
            </div>
        );
    }, [latestSaved, onChangeView, t]);

    const renderLatestWatchedCarousel = useMemo(() => {
        return (
            <div className="accountMobileContainer-content_mainRow">
                <div className="accountMobileContainer-content_actionRow">
                    <h3 className="accountMobileContainer-content_minorTitle">{t('account_page.mobile.watched')}</h3>
                    {latestWatched.length > 0 ? (
                        <Button
                            text={t('account_page.mobile.view_all')}
                            onClick={() => {
                                onChangeView(AccountView.WATCHED);
                            }}
                            styleClass="linkYellow"
                        />
                    ) : null}
                </div>
                <div className="accountMobileContainer-content_carousel -contents">
                    {latestWatched.length > 0 ? (
                        latestWatched.map((saved) => {
                            return (
                                <div>
                                    <img src={saved.img} alt={saved.title} />
                                </div>
                            );
                        })
                    ) : (
                        <p className="accountMobileContainer-content_carousel__noElements">
                            {t('account_page.mobile.no_watched')}
                        </p>
                    )}
                </div>
            </div>
        );
    }, [latestWatched, onChangeView, t]);

    const renderEditSettings = useMemo(() => {
        return (
            <div className="accountMobileContainer-content_mainRow">
                <div className="accountMobileContainer-content_actionRow">
                    <h3 className="accountMobileContainer-content_minorTitle">
                        {t('account_page.mobile.user_settings')}
                    </h3>
                    <Button
                        text={t('account_page.mobile.edit')}
                        onClick={() => {
                            onChangeView(AccountView.SETTINGS);
                        }}
                        styleClass="linkYellow"
                    />
                </div>
            </div>
        );
    }, [onChangeView, t]);

    const renderLogout = useMemo(() => {
        return (
            <div className="accountMobileContainer-content_mainRow">
                <div className="accountMobileContainer-content_actionRow">
                    <Button text={t('account_page.mobile.logout')} onClick={onLogout} styleClass="linkPurple" />
                </div>
            </div>
        );
    }, [onLogout, t]);

    useEffect(() => {
        if (user) {
            fetchLatestSaved();
            fetchLatestWatched();
        }
    }, [fetchLatestSaved, fetchLatestWatched, user]);

    return (
        <div className="accountMobileContainer-content">
            <h2 className="accountMobileContainer-content_subtitle">{t('account_page.mobile.search_preferences')}</h2>
            {renderProviderCarousel}
            {renderGenreCarousel}
            {renderLatestSavedCarousel}
            {renderLatestWatchedCarousel}
            {renderEditSettings}
            {renderLogout}
            <LoadingModal isVisible={loading} />
            <Toast message={error} onClose={handleCloseToast} type={'error'} />
        </div>
    );
};

export default AccountOverView;
