import { FC, useCallback, useMemo, useState } from 'react';
import { SavedType, WatchedType } from '../../models/user';
import './Content.scss';
import ButtonIcon from '../../components/ButtonIcon';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import LikePopover from '../../components/Popovers/LikePopover';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { ContentType } from '../../utilities/enum/contentType';

interface Props {
    content: SavedType | WatchedType;
    type: 'watched' | 'saved';
    contentType: ContentType;
    onRemove: () => void;
    onWatched?: (status: boolean) => void;
    onChangeStatus?: () => void;
}

const Content: FC<Props> = ({ content, type, contentType, onRemove, onWatched, onChangeStatus }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [removeContent, setRemoveContent] = useState(false);
    const [likeAction, setLikeAction] = useState<HTMLDivElement | null>(null);

    const handleOpenModal = useCallback(() => {
        setRemoveContent(true);
    }, []);

    const handleCancelDelete = useCallback(() => {
        setRemoveContent(false);
    }, []);

    const handleRemoveContent = useCallback(() => {
        setRemoveContent(false);
        onRemove();
    }, [onRemove]);

    const handleWatchedContent = useCallback(
        (status: boolean) => {
            if (onWatched) {
                onWatched(status);
                setLikeAction(null);
            }
        },
        [onWatched],
    );

    const handleGoToContent = useCallback(() => {
        navigate(`${ROUTES.RESULT_PAGE}/${content.idContent}`, {
            state: {
                type: contentType,
            },
        });
    }, [content.idContent, navigate, contentType]);

    const renderWatchedIcon = useMemo(() => {
        if (type === 'saved') {
            return (
                <>
                    <ButtonIcon
                        icon={'favorite'}
                        onClick={(target) => {
                            setLikeAction(target);
                        }}
                        size={30}
                    />
                    <LikePopover
                        element={likeAction}
                        onClose={() => setLikeAction(null)}
                        onAdd={handleWatchedContent}
                    />
                </>
            );
        } else {
            return (
                <ButtonIcon
                    icon={(content as WatchedType).isLike ? 'like' : 'dislike'}
                    onClick={
                        onChangeStatus
                            ? onChangeStatus
                            : () => {
                                  return;
                              }
                    }
                    size={30}
                />
            );
        }
    }, [content, handleWatchedContent, likeAction, onChangeStatus, type]);

    return (
        <div className="content" onClick={handleGoToContent}>
            <p className="content-title">{content.title}</p>
            <img className="content-cover" src={content.img} width="100%" loading="lazy" alt={content.title} />
            <div className="content-rowActions">
                {renderWatchedIcon}
                <ButtonIcon icon={'remove'} onClick={handleOpenModal} size={30} />
            </div>
            <ConfirmModal
                isVisible={removeContent}
                answer={
                    type === 'saved'
                        ? t('account_page.desktop.watchlist.remove', { title: content.title })
                        : t('account_page.desktop.watched.remove', { title: content.title })
                }
                cancelText={
                    type === 'saved'
                        ? t('account_page.desktop.watchlist.remove_no')
                        : t('account_page.desktop.watched.remove_no')
                }
                confirmText={
                    type === 'saved'
                        ? t('account_page.desktop.watchlist.remove_yes')
                        : t('account_page.desktop.watched.remove_yes')
                }
                onCancel={handleCancelDelete}
                onConfirm={handleRemoveContent}
            />
        </div>
    );
};

export default Content;
