// export const LOGO = 'https://whatwatch-files.s3.eu-west-2.amazonaws.com/whatwatch-logo.svg';

export const API_KEY = '44f48e7b28d4b0278ee574993973d95a';

export const baseUrlImage = 'https://image.tmdb.org/t/p/original';

export const baseYouTube = 'https://www.youtube.com/watch?v=';

export const baseUrlPlatform = 'https://api.whatwatch.tv/';

export const COLORS = {
    purple: '#F316B4',
    yellow: '#F7D632',
};

export const firebaseConfig = {
    apiKey: 'AIzaSyCxBBq3arShnc4Moj8h_OedmrKjj4yjpVM',
    authDomain: 'whatwatch-v2.firebaseapp.com',
    projectId: 'whatwatch-v2',
    storageBucket: 'whatwatch-v2.appspot.com',
    messagingSenderId: '878047252528',
    appId: '1:878047252528:web:3796330b8494dcbcf39df5',
};

// export const genresImgUrl = (id: number) => {
//   return `https://whatwatch-files.s3.eu-west-2.amazonaws.com/emojis/genres/${id}.png`;
// };

// export const feelingsImgUrl = (id: number) => {
//     return `https://whatwatch-files.s3.eu-west-2.amazonaws.com/emojis/feelings/${id}.png`;
// };

// export const defaultGenresImgUrl = 'https://whatwatch-files.s3.eu-west-2.amazonaws.com/emojis/genres/default.png';

// export const defaultFeelingsImgUrl = 'https://whatwatch-files.s3.eu-west-2.amazonaws.com/emojis/feelings/default.png';

// export const feelingsModalImg = 'https://whatwatch-files.s3.eu-west-2.amazonaws.com/feelings.png';

export const privacyPolicyLink = 'https://www.iubenda.com/privacy-policy/65388923/legal';

export const facebookAppId = '1386524502269231';
